import React from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import { Provider } from "react-redux";
import { NotificationContainer } from "react-notifications";
import { PersistGate } from "redux-persist/es/integration/react";
import "./App.css";
import { configureStore } from "./redux/store";
import { setLoading } from "./redux/actions/loaderActions";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "react-notifications/lib/notifications.css";
import RegisterUser from "./screens/RegisterUser";
import Dashboard from './screens/Dashboard';
import CompleteLogin from './screens/CompleteLogin';
import InitiateLogin from './screens/InitiateLogin';
import AuthenticatedRoute from "./layouts/AuthenticatedRoute";
import Loader from "./components/Loader";
import GuestRoute from "./layouts/GuestRoute";
import UtilityRoute from "./layouts/UtilityRoute";

const { store, persistor } = configureStore();

// axios headers for all the APIs
axios.defaults.headers.post["Content-Type"] = "application/json";

// Add a request interceptor
axios.interceptors.request.use(
  function (config) {
    store.dispatch(setLoading(true));
    return config;
  },
  function (error) {
    store.dispatch(setLoading(false));
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    store.dispatch(setLoading(false));
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      localStorage.removeItem('auth');
      window.location.href = '/';
    }

    store.dispatch(setLoading(false));
    return Promise.reject(error);
  }
);

class App extends React.Component {
  constructor(props) {
    super(props);
    Sentry.init({
      environment: process.env.REACT_APP_ENV,
      // dsn: "https://a092ae200946420f8adcecea70c1ee1a@o1015769.ingest.sentry.io/5981440",
    });
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Loader />
          <BrowserRouter>
            <div >
                <Switch>
                  <GuestRoute exact path="/login" component={InitiateLogin} />
                  <UtilityRoute exact path="/finish-login" component={CompleteLogin} />
                  <GuestRoute exact path="/register" component={RegisterUser} />
                  <AuthenticatedRoute exact path="/" component={Dashboard} />
                  {/* <AuthenticatedRoute
                    path="/settings"
                    exact
                    component={Settings}
                  /> */}
                </Switch>
            </div>
          </BrowserRouter>
          <NotificationContainer />
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
