import * as types from "../actionTypes";
import produce from "immer";

const initialState = {
  subscriptionDetails: {},
  errors: [],
};

export default function subscriptionReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case types.FETCH_SUBSCRIPTION_SUCCESS:
      return produce(state, (draft) => {
        draft.subscriptionDetails = {...action.subscriptionDetails, expires_at_date_object: new Date(action.subscriptionDetails.expires_at)};
        draft.errors = [];
      });
    case types.FETCH_SUBSCRIPTION_FAILED:
      return produce(state, (draft) => {
        draft.subscriptionDetails = {};
        draft.errors = [action.error];
      });
    default:
      return state;
  }
}
