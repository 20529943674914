import React from "react";
import OnBoardingTypeform from "../components/OnBoardingTypeform";
import { useDispatch } from "react-redux";
import { registerUser } from "../redux/actions/authActions";
import { NotificationManager } from "react-notifications";

const RegisterUser = () => {
  const dispatch = useDispatch();

  const registerUserHandler = async (data, completeForm, setIsSubmitting) => {
    const { answers } = data;
    const requestData = {
      name: answers["name"]["value"],
      email: answers["email"]["value"],
      timeSlot: answers["timeSlot"]["value"],
      recipientName:
        answers["checkedin-user"]["value"][0] === "me"
          ? answers["name"]["value"]
          : answers["recipientName"]["value"],
      phoneNumber:
        answers["checkedin-user"]["value"][0] === "me"
          ? answers["phoneNumber"]["value"]
          : answers["recipientPhoneNumber"]["value"],
    };

    try {
      await dispatch(registerUser(requestData, false));
      window.location.href = 'https://checklijn.nl/welkom';
    } catch (error) {
      setIsSubmitting(false);
      NotificationManager.error(
        "Unable to register the user! Please try again"
      );
    }
  };
  return (
    <div className="flex flex-row justify-center w-full h-full max-w-md">
      <OnBoardingTypeform registerUserHandler={registerUserHandler} />
    </div>
  );
};
export default RegisterUser;
