import React, {useEffect, useState} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { consumeMagicLink } from "../redux/actions/authActions";
import { NotificationManager } from "react-notifications";

const CompleteLogin = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const [token, setToken] = useState("");

  useEffect(() => {
    const handleSubmit = async ()=>{
      const data={
        token
      }
      try{
        await dispatch(consumeMagicLink(data))
        history.push('/')
        NotificationManager.success("👋 Welkom, User! U bent succesvol ingelogd op het Checklijn dashboard! 🎉")
      }
      catch(error){
        NotificationManager.error("Account niet gevonden. Controleer de opgegeven gegevens en probeer het opnieuw.")
      }
    }

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('token')) {
      setToken(searchParams.get('token'));
      if (token) {
        handleSubmit();
      }
    } else {
      NotificationManager.error("De link die u heeft gebruikt lijkt te zijn verlopen. Gelieve het aanmeldproces opnieuw te proberen.")
    }
  }, [dispatch, history, token, location.search]);

  return (
    <div className="flex flex-row justify-center px-5 w-full max-w-md">
      <div className="flex-grow">
        <div className="text-3xl font-medium text-neutral-600">
          Welkom bij Checklijn! 👋
        </div>
        <div className="text-base my-2  text-neutral-700">
          Inloggen...
        </div>
      </div>
    </div>
  );
};

export default CompleteLogin;
