import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { generateMagicLink } from "../redux/actions/authActions";
import { NotificationManager } from "react-notifications";

const InitiateLogin = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async()=>{
    const data={
        email
    }
    try{
        await dispatch(generateMagicLink(data))
        setSubmitted(true);
    }
    catch(error){
        NotificationManager.error("Account niet gevonden. Controleer de opgegeven gegevens en probeer het opnieuw.")
    }
  }

  return (
    <div className="flex flex-row justify-center px-5 w-full max-w-md">
      {
        submitted
          ? <div className="flex-grow">
            <div className="text-3xl font-medium text-neutral-600">
              Check je e-mail!
            </div>
            <div className="text-base my-2  text-neutral-700">
              Er is een inloglink naar je e-mail gestuurd. Indien je het niet binnen 3 minuten ontvangt, controller dan je spambox.
            </div>
          </div>
          : <div className="flex-grow">
            <div className="text-3xl font-medium text-neutral-600">
              Welkom bij Checklijn! 👋
            </div>
            <div className="text-base my-2  text-neutral-700">
              Log in op uw account
            </div>
            <div className="my-4 mt-8">
              <label
                className="block text-sm mb-1 text-font text-neutral-600"
                htmlFor="email"
              >
                E-mail
              </label>
              <input
                className="shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100"
                id="email"
                type="email"
                placeholder="voorbeeld@voorbeeld.nl"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </div>
            <button
              className="bg-primary-100 text-neutral-100 py-2 px-4 rounded focus:outline-none focus:shadow-outline my-4 w-full"
              type="button"
              onClick={handleSubmit}
            >
              Inloggen
            </button>
            <div className="flex flex-row justify-center">
              <div>
                <div className="text-sm"> Bent u nieuw bij Checklijn?</div>
                <Link to="/register" className="text-primary-100 text-sm">
                  Maak hier een account aan.
                </Link>
              </div>
            </div>
          </div>
      }
    </div>
  );
};

export default InitiateLogin;
