import * as actionTypes from '../actionTypes'

export const setLoading = (value) => {
  return async (dispatch) => {
    dispatch(setLoadingSuccess(value))
  }
}

export const setLoadingSuccess = (res) => {
  return { type: actionTypes.SET_LOADING, res }
}
