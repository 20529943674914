import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { fetchBillingLink } from "../redux/actions/billingActions";
import { fetchSubscriptionDetails } from "../redux/actions/subscriptionActions";

function AuthenticatedRoute({ component: SubComp, authenticated, ...rest }) {
  const dispatch = useDispatch();
  const currentUser = localStorage.getItem('auth');

  useEffect(() => {
    if (currentUser) {
      dispatch(fetchBillingLink());
      dispatch(fetchSubscriptionDetails());
    }
  }, [currentUser, dispatch]);

  return (
    <Route
      {...rest}
      render={props =>
        currentUser ? (
          <SubComp {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

export default AuthenticatedRoute;
