import axios from "axios";
import * as actionTypes from "../actionTypes";

export const fetchReceipientDetails = () => {
  return async (dispatch, getState) => {
    const currentUser = getState().auth.currentUser;
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}recipient`, {
        headers: {
          Authorization: currentUser.token,
        },
      })
      .then(async (result) => {
        await dispatch(fetchReceipientSuccess(result.data.data));
      })
      .catch(async (err) => {
        await dispatch(fetchReceipientFailed());
      });
  };
};

export const fetchReceipientSuccess = (res) => {
  return { type: actionTypes.FETCH_RECIPIENT_SUCCESS, res };
};

export const fetchReceipientFailed = (error) => {
  return { type: actionTypes.FETCH_RECIPIENT_FAILED };
};

export const fetchUserEmergencyDetails = () => {
  return async (dispatch , getState) => {
    const currentUser = getState().auth.currentUser;
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}emergency-details`, {
        headers: {
          Authorization: currentUser.token,
        },
      })
      .then(async (result) => {
        await dispatch(fetchUserEmergencyDetailsSuccess(result.data.data));
      })
      .catch(async (err) => {
        await dispatch(fetchUserEmergencyDetailsFailed());
      });
  };
};

export const fetchUserEmergencyDetailsSuccess = (res) => {
  return { type: actionTypes.FETCH_USER_EMERGENCY_DETAILS_SUCCESS, res };
};

export const fetchUserEmergencyDetailsFailed = (error) => {
  return { type: actionTypes.FETCH_USER_EMERGENCY_DETAILS_FAILED };
};

export const setRecepientDetails = (key, value) => {
  return { type: actionTypes.SET_RECEPIENT_DETAILS, key, value };
};

export const updatedRecipient = () => {
  return async (dispatch, getState) => {
    const recipientDetails = getState().recipient.updatedRecipientDetails;
    const currentUser = getState().auth.currentUser;

    const recipientId = recipientDetails.id;

    const data = {
      recipientName: recipientDetails.name,
      phoneNumber: recipientDetails.phone_number,
      timeSlot1: recipientDetails.calling_timeslot1,
      timeSlot2: recipientDetails.calling_timeslot2
        ? recipientDetails.calling_timeslot2
        : "",
    };

    return axios
      .put(`${process.env.REACT_APP_BACKEND_URL}recipient/update/${recipientId}`, data, {
        headers: {
          Authorization: currentUser.token,
        },
      })
      .then(async () => {
        await dispatch(fetchReceipientDetails());
      })
      .catch(async (err) => {
        console.log(err);
        throw err;
      });
  };
};

export const addEmergencyContact = (data) => {
  return async (dispatch, getState) => {
    const currentUser = getState().auth.currentUser;

    return axios
      .post(`${process.env.REACT_APP_BACKEND_URL}emergency-details/add`, data, {
        headers: {
          Authorization: currentUser.token,
        },
      })
      .then(async () => {
        await dispatch(fetchUserEmergencyDetails());
      })
      .catch(async (err) => {
        console.log(err);
        throw new Error(err);
      });
  };
};

export const deleteRecipientEmergency = (emergencyId) => {
  return async (dispatch, getState) => {
    const currentUser = getState().auth.currentUser;

    return axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}emergency-details/delete/${emergencyId}`, {
        headers: {
          Authorization: currentUser.token,
        },
      })
      .then(async () => {
        await dispatch(fetchUserEmergencyDetails());
      })
      .catch(async (err) => {
        console.log(err);
        throw new Error(err);
      });
  };
};

