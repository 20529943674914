import axios from 'axios'
import * as actionTypes from '../actionTypes'

const { REACT_APP_BACKEND_URL } = process.env;

export const fetchBillingLink = (data) => {
  return (dispatch, getState) => {
    const currentUser = getState().auth.currentUser;
    return axios
      .get(`${REACT_APP_BACKEND_URL}stripe/link`, {
        headers: {
          Authorization: currentUser.token,
        },
      })
      .then((result) => {
        dispatch(fetchBillingLinkSuccess(result.data.data))
      })
      .catch((err) => {
        dispatch(fetchBillingLinkFailed(err))
      })
  }
}

export const fetchBillingLinkSuccess = (response) => {
  return { type: actionTypes.FETCH_BILLING_LINK_SUCCESS, redirectUrl: response.billing_url }
}

export const fetchBillingLinkFailed = (error) => {
  return { type: actionTypes.FETCH_BILLING_LINK_FAILED, error: error }
}
