import axios from "axios";
import * as actionTypes from "../actionTypes";

export const fetchSubscriptionDetails = () => {
  return async (dispatch, getState) => {
    const currentUser = getState().auth.currentUser;
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}subscription`, {
        headers: {
          Authorization: currentUser.token,
        },
      })
      .then(async (result) => {
        await dispatch(fetchSubscriptionSuccess(result.data.data));
      })
      .catch(async (err) => {
        await dispatch(fetchSubscriptionFailed(err));
      });
  };
};

export const fetchSubscriptionSuccess = (res) => {
  return { type: actionTypes.FETCH_SUBSCRIPTION_SUCCESS, subscriptionDetails: res };
};

export const fetchSubscriptionFailed = (error) => {
  return { type: actionTypes.FETCH_SUBSCRIPTION_FAILED, error: error };
};
