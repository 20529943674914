import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteRecipientEmergency } from "../redux/actions/recipientActions";
import { NotificationManager } from "react-notifications";

const EmergencyDetailsTable = (props) => {
  const { setModalOpen } = props;

  const dispatch = useDispatch();

  const emergencyDetails = useSelector(
    (state) => state.recipient.emergencyDetails
  );

  const deleteEmergencyHandler = async(id, name)=>{
    try{
      await dispatch(deleteRecipientEmergency(id));
      NotificationManager.success(`${name} is verwijderd als sleutelfiguur.`)
    }
    catch(error){
      NotificationManager.error("Unable to delete recipient emergency!")
    }
  }

  return (
    <div className="mt-8 m-8 shadow-md px-6 bg-neutral-100 rounded-md">
      <div className="bg-white rounded pt-6 pb-4 mb-4 flex flex-col md:flex-row justify-between">
        <div className="text-lg font-medium text-neutral-dark-100 pt-2">
          Sleutelfiguren
        </div>
        <button
          className="bg-primary-100 text-neutral-100 py-2 px-4 mt-4 md:mt-0 rounded focus:outline-none focus:shadow-outline"
          type="button"
          onClick={() => setModalOpen(true)}
        >
          Sleutelfiguur Toevoegen
        </button>
      </div>
      <div id="emergencies-desktop-view" className="hidden md:block">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700">
            <tr>
              <th scope="col" className="py-2">
                Naam
              </th>
              <th scope="col" className="py-2">
                Telefoonnummer
              </th>
              <th scope="col" className="py-2">
                Relatie
              </th>
              <th scope="col" className="py-2">
                Status
              </th>
              <th scope="col" className="py-2">
                Verwijder
              </th>
            </tr>
          </thead>
          <tbody>
            {emergencyDetails &&
              emergencyDetails.length > 0 &&
              emergencyDetails.map((user) => (
                <tr key={user.id}>
                  <td className="py-4 font-medium text-gray-900 whitespace-nowrap">
                    {user.name}
                    <div className="text-neutral-800 text-xs">{user.email}</div>
                  </td>
                  <td className="py-4">{user.phone_number}</td>
                  <td className="py-4">{user.relation}</td>
                  <td className="py-4">
                    {user.status === "pending" ? (
                      <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-neutral-100 bg-primary-100 uppercase last:mr-0 mr-1">
                        In afwachting
                      </span>
                    ) : (
                      <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-neutral-100 bg-success-500 uppercase last:mr-0 mr-1">
                        Actief
                      </span>
                    )}
                  </td>
                  <td className="py-4 px-4">
                    <span className="cursor-pointer" onClick={()=>deleteEmergencyHandler(user.id, user.name)}>
                      <DeleteIcon />
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div id="emergencies-mobile-view" className="block md:hidden">
        {emergencyDetails &&
          emergencyDetails.length > 0 &&
          emergencyDetails.map((user) => (
            <div key={user.id} className="border-t-1 pb-4">
              <div className="py-1 font-medium text-gray-900 whitespace-nowrap">
                {user.name}
                <div className="text-neutral-800 text-xs">{user.email}</div>
              </div>
              <div className="py-1 w-full flex flex-row justify-between">
                <span>{user.phone_number}</span>
                <span>/</span>
                <span>{user.relation}</span>
              </div>
              <div className="py-1 w-full flex flex-row justify-between">
                {user.status === "pending" ? (
                  <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-neutral-100 bg-primary-100 uppercase last:mr-0 mr-1">
                    In afwachting
                  </span>
                ) : (
                  <span className="text-xs font-semibold inline-block py-1 px-2 rounded text-neutral-100 bg-success-500 uppercase last:mr-0 mr-1">
                    Actief
                  </span>
                )}
                <span className="cursor-pointer" onClick={()=>deleteEmergencyHandler(user.id, user.name)}>
                  <DeleteIcon />
                </span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default EmergencyDetailsTable;
