import { combineReducers } from 'redux';
import loader from './loaderReducer';
import auth from './authReducer';
import billing from './billingReducer';
import recipient from './recipientReducer'
import subscription from './subscriptionReducer'

const rootReducer = () => combineReducers({
  loader: loader,
  auth: auth,
  billing: billing,
  recipient: recipient,
  subscription: subscription,
});

export default rootReducer;
