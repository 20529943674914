import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const isLoading = useSelector(
    state => state.loader.loading
  );

  return (
    isLoading &&
    <div className="fixed w-full h-full flex flex-col justify-center items-center z-10 bg-neutral-100 opacity-90">
      <div className="rounded drop-shadow-2xl p-8 flex flex-col justify-center items-center bg-primary-100  text-neutral-100">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
             className="w-12 h-12 animate-spin">
          <path strokeLinecap="round" strokeLinejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
        </svg>
        <p className="mt-4 font-semibold italic">Laden . . .</p>
      </div>
    </div>
  );
};

export default Loader;
