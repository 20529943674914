import * as types from "../actionTypes";
import produce from "immer";

const initialState = {
  currentUser: {},
};

export default function authReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case types.GENERATE_MAGIC_LINK_SUCCESS:
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    case types.GENERATE_MAGIC_LINK_FAILED:
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    case types.CONSUME_MAGIC_LINK_SUCCESS:
      return produce(state, (draft) => {
        draft.currentUser = action.currentUser;
      });
    case types.CONSUME_MAGIC_LINK_FAILED:
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    case types.REGISTER_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.currentUser = action.currentUser;
      });
    case types.REGISTER_USER_FAILED:
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    case types.LOGOUT_USER_SUCCESS:
      return produce(state, (draft) => {
        draft.currentUser = {};
      });
    default:
      return state;
  }
}
