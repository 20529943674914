import * as types from "../actionTypes";
import produce from "immer";

const initialState = {
  loading: false,
};

export default function loaderReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case types.SET_LOADING:
      return produce(state, (draft) => {
        draft.loading = action.res;
      });
    default:
      return state;
  }
}
