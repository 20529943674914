import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import logo from "../assets/checklijn.png";
import loginImage from "../assets/login.svg";

function GuestRoute({ component: SubComp, authenticated, ...rest }) {
  const currentUser = localStorage.getItem('auth');

  return (
    <Route
      {...rest}
      render={props =>
        currentUser ? (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        ) : (
          <div className="h-screen">
            <img src={logo} className="w-32 mt-6 ml-8 absolute" alt="checklijn application logo"/>
            <div className="grid grid-cols-3 h-full">
              <div className="bg-neutral-500 hidden lg:block lg:col-span-2">
                <div className="">
                  <img src={loginImage} className="h-screen" alt="checklijn branding"/>
                </div>
              </div>
              <div className="flex flex-col justify-center items-center pt-20 col-span-3 lg:col-span-1">
                <SubComp {...props} {...rest} />
              </div>
            </div>
          </div>
        )
      }
    />
  );
}

export default GuestRoute;
