import React, { useState } from "react";
import { Modal } from "@mui/base";
import { Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PersonIcon from "@mui/icons-material/Person";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch } from "react-redux";
import { addEmergencyContact } from "../redux/actions/recipientActions";
import Swal from "sweetalert2";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  maxWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2.5,
};
const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

const EmergencyDetailsModal = (props) => {
  const { isModalOpen, setModalOpen } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [relation, setRelation] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (name && regex.test(email) && phoneNumber && relation) {
      const data = {
        name,
        email,
        phoneNumber,
        relation,
      };
      await dispatch(addEmergencyContact(data));
      setModalOpen(false);
      await Swal.fire({
        icon: "success",
        title: "In afwachting van bevestiging",
        html: `${data.name} moet de rol als sleutelfiguur bevestigen door de bevestigingsmail te accepteren.`,
        confirmButtonText: "Oké",
      });
    }
  };

  return (
    <Modal open={isModalOpen} onClose={() => setModalOpen(false)}>
      <Box sx={style} className="p-4">
        <div className="flex flex-row justify-between">
          <div className="text-lg font-medium text-neutral-dark-100">
            Sleutelfiguur Toevoegen
          </div>
          <div className="cursor-pointer" onClick={() => setModalOpen(false)}>
            <CloseIcon />
          </div>
        </div>
        <div className="grid grid-cols-3 my-4">
          <label
              className="block text-sm pt-2 text-font text-neutral-600 col-span-3 md:col-span-1"
              htmlFor="name"
          >
            Naam
          </label>
          <div className="mb-6 col-span-3 md:col-span-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <PersonIcon />
              </div>
              <input
                  className="pl-10 p-2.5 shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100 col-span-2"
                  id="name"
                  type="text"
                  placeholder="Volledige naam"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
              />
            </div>
            {isSubmitted && !name && (
                <span className="text-error-500 mt-2">Please enter name</span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 my-4">
          <label
              className="block text-sm pt-2 text-font text-neutral-600 col-span-3 md:col-span-1"
              htmlFor="name"
          >
            E-mail
          </label>
          <div className="mb-4 col-span-3 md:col-span-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <EmailIcon />
              </div>
              <input
                  className="pl-10 p-2.5 shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100 col-span-2"
                  id="email"
                  type="email"
                  placeholder="voorbeeld@voorbeeld.nl"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            {isSubmitted && (!regex.test(email) || !email) && (
                <span className="text-error-500 mt-2">
              Please enter a valid email
            </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 my-4">
          <label
              className="block text-sm pt-2 text-font text-neutral-600 col-span-3 md:col-span-1"
              htmlFor="name"
          >
            Telefoonnummer
          </label>
          <div className="mb-4 col-span-3 md:col-span-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <PhoneAndroidIcon />
              </div>
              <input
                  className="pl-10 p-2.5 shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100 col-span-2"
                  id="name"
                  type="number"
                  placeholder="0612345678"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>

            {isSubmitted && !phoneNumber && (
                <span className="text-error-500 mt-2">
              Please enter phone number
            </span>
            )}
          </div>
        </div>
        <div className="grid grid-cols-3 my-4">
          <label
              className="block text-sm pt-2 text-font text-neutral-600 col-span-3 md:col-span-1"
              htmlFor="name"
          >
            Relatie
          </label>
          <div className="mb-4 col-span-3 md:col-span-2">
            <div className="relative">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <PeopleAltIcon />
              </div>
              <input
                  className="pl-10 p-2.5 shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100 col-span-2"
                  id="name"
                  type="text"
                  placeholder="Buurman, dochter, etc."
                  value={relation}
                  onChange={(e) => setRelation(e.target.value)}
              />
            </div>
            {isSubmitted && !relation && (
                <span className="text-error-500 mt-2">
              Please enter relation with the recipient
            </span>
            )}
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <button
              className="bg-primary-100 text-sm font-medium text-neutral-100 py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full md:max-w-[8rem]"
              onClick={handleSubmit}
              disabled={isSubmitted}
          >
            Opslaan
          </button>
        </div>
      </Box>
    </Modal>
  );
};

export default EmergencyDetailsModal;
