import {Form, useFieldAnswer} from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import {registerCoreBlocks} from "@quillforms/react-renderer-utils";

const timeSlots = [
  "06:00",
  "06:15",
  "06:30",
  "06:45",
  "07:00",
  "07:15",
  "07:30",
  "07:45",
  "08:00",
  "08:15",
  "08:30",
  "08:45",
  "09:00",
  "09:15",
  "09:30",
  "09:45",
  "10:00",
  "10:15",
  "10:30",
  "10:45",
  "11:00",
  "11:15",
  "11:30",
  "11:45",
  "12:00",
  "12:15",
  "12:30",
  "12:45",
  "13:00",
  "13:15",
  "13:30",
  "13:45",
  "14:00",
  "14:15",
  "14:30",
  "14:45",
  "15:00",
  "15:15",
  "15:30",
  "15:45",
  "16:00",
  "16:15",
  "16:30",
  "16:45",
  "17:00",
  "17:15",
  "17:30",
  "17:45",
  "18:00",
  "18:15",
  "18:30",
  "18:45",
  "19:00",
  "19:15",
  "19:30",
  "19:45",
  "20:00",
  "20:15",
  "20:30",
  "20:45",
  "21:00",
  "21:15",
  "21:30",
  "21:45",
  "22:00",
  "22:15",
  "22:30",
  "22:45",
  "23:00",
  "23:15",
  "23:30",
  "23:45",
  "00:00",
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  "01:15",
  "01:30",
  "01:45",
  "02:00",
  "02:15",
  "02:30",
  "02:45",
  "03:00",
  "03:15",
  "03:30",
  "03:45",
  "04:00",
  "04:15",
  "04:30",
  "04:45",
  "05:00",
  "05:15",
  "05:30",
  "05:45",
];

const getTimeSlots = () => {
  let slots = []

  slots = timeSlots.map(slot => {
    return {
      label: slot,
      value: slot
    }
  })
  return slots
}

registerCoreBlocks();

const OnBoardingTypeform = ({registerUserHandler}) => {
  const checkedinUser = useFieldAnswer("checkedin-user");
  const recipientName = useFieldAnswer("recipientName");

  return (
    <div className="flex-grow">
      <Form
        formId="1"
        formObj={{
          messages: {
            "label.button.ok": 'Volgende stap',
            "label.hintText.enter": "druk op <strong>Enter ↵</strong>",
            "label.hintText.multipleSelection": "Kies er zoveel als je wilt",
            "block.dropdown.placeholder": "Typ of selecteer een optie",
            "block.dropdown.noSuggestions": "Geen suggesties!",
            "block.shortText.placeholder": "Typ hier je antwoord",
            "block.longText.placeholder": "Typ hier je antwoord",
            "block.longText.hint": "<strong>Shift ⇧ + Enter ↵</strong> to make a line break",
            "block.number.placeholder": "Typ hier je antwoord",
            "block.email.placeholder": "Typ hier je E-mail",
            "block.defaultThankYouScreen.label": "Bedankt voor het invullen.\n\n We nemen spoedig contact met u op",
            "label.hintText.key": "Toets",
            "label.progress.percent": "{{progress:percent}}% compleet",
            "label.errorAlert.required": "Dit veld is verplicht!",
            "label.errorAlert.date": "Ongeldige datum!",
            "label.errorAlert.number": "Alleen nummers!",
            "label.errorAlert.selectionRequired": "Maak minimaal één keuze!",
            "label.errorAlert.email": "Invalid email!",
            "label.errorAlert.url": "Ongeldige URL!",
            "label.errorAlert.range": "Voer een getal tussen in {{attribute:min}} en {{attribute:max}}",
            "label.errorAlert.minNum": "Voer een getal in dat groter is dan {{attribute:min}}",
            "label.errorAlert.maxNum": "Vul a.u.b. een getal in dat lager is dan {{attribute:max}}",
            "label.errorAlert.maxCharacters": "Maximum aantal tekens bereikt!",
            "label.submitBtn": "Voltooien",
          },
          blocks: [
            {
              name: "welcome-screen",
              id: "jg1401r",
              attributes: {
                label: "Welkom bij Checklijn! 👋",
                description: "Laten we beginnen",
                buttonText: "Starten",
              },
            },
            {
              name: "group",
              id: "name-group",
              innerBlocks: [
                {
                  id: "name",
                  name: "short-text",
                  attributes: {
                    label: "Wat is je naam?",
                    placeholder: "Typ hier je antwoord",
                    required: true,
                  },
                },
                {
                  id: "checkedin-user",
                  name: "multiple-choice",
                  attributes: {
                    label: "Wie zal de dagelijkse belletjes ontvangen?",
                    required: true,
                    choices: [
                      {
                        label: "Ik",
                        value: "me",
                      },
                      {
                        label: "Een naaste",
                        value: "someone-else",
                      },
                    ],
                  },
                },
              ],
            },
            ...(checkedinUser && checkedinUser.includes("me")
              ? [
                {
                  id: "phoneNumber",
                  name: "short-text",
                  attributes: {
                    label:
                      "Op welk nummer wilt u de dagelijkse belletjes ontvangen?",
                    placeholder: "0612345678",
                  },
                },
                {
                  name: "group",
                  id: "time-slot-group",
                  attributes: {
                    label: "Op welk tijdstip wilt u de dagelijkse belletjes ontvangen?",
                  },
                  innerBlocks: [
                    {
                      id: "timeSlot",
                      name: "dropdown",
                      attributes: {
                        label: `Kies het tijdstip`,
                        placeholder: "Kies het tijdstip",
                        choices: getTimeSlots()
                      },
                    },
                  ],
                },
                {
                  name: "group",
                  id: "user-info-group",
                  attributes: {
                    label: "Je accountgegevens",
                  },
                  innerBlocks: [
                    {
                      id: "email",
                      name: "email",
                      attributes: {
                        label: "Wat is je e-mail?",
                        placeholder: "Typ hier je antwoord",
                        required: true,
                      },
                    },
                  ],
                },
              ]
              : [
                {
                  name: "group",
                  id: "recipient-group",
                  attributes: {
                    label: "Vul de gegevens in van je naaste",
                  },
                  innerBlocks: [
                    {
                      id: "recipientName",
                      name: "short-text",
                      attributes: {
                        label: "Wat is de naam van je naaste?",
                        placeholder: "Typ hier je antwoord",
                      },
                    },
                    {
                      id: "recipientPhoneNumber",
                      name: "short-text",
                      attributes: {
                        label:
                          "Op welk telefoonnummer wilt je naaste de dagelijkse belletjes ontvangen?",
                        placeholder: "0612345678",
                      },
                    },
                  ],
                },

                {
                  name: "group",
                  id: "time-slot-group",
                  attributes: {
                    label: `Op welk tijdstip wilt ${recipientName} de dagelijkse check ontvangen?`,
                  },
                  innerBlocks: [
                    {
                      id: "timeSlot",
                      name: "dropdown",
                      attributes: {
                        label: `Kies het tijdstip`,
                        placeholder: "Kies het tijdstip",
                        choices: getTimeSlots()
                      },
                    },
                  ],
                },
                {
                  name: "group",
                  id: "user-info-group",
                  attributes: {
                    label: "Je accountgegevens",
                  },
                  innerBlocks: [
                    {
                      id: "email",
                      name: "email",
                      attributes: {
                        label: "Wat is je e-mail?",
                        placeholder: "Typ hier je antwoord",
                        required: true,
                      },
                    },
                    {
                      id: "phoneNumber",
                      name: "short-text",
                      attributes: {
                        label: "what's your phone number",
                        placeholder: "0612345678",
                        required: true,
                      },
                    },
                  ],
                },
              ]),
          ],
          settings: {
            animationDirection: "vertical",
            disableWheelSwiping: true,
            disableNavigationArrows: false,
            disableProgressBar: false,
          },
          theme: {
            font: "'Montserrat', sans-serif",
            buttonsBgColor: "#7366F0",
            logo: {
              src: "",
            },
            questionsColor: "#000",
            answersColor: "#7366F0",
            buttonsFontColor: "#fff",
            buttonsBorderRadius: 25,
            errorsFontColor: "#fff",
            errorsBgColor: "#EA5354",
            progressBarFillColor: "#7367f0",
            progressBarBgColor: "#ccc",
          },
        }}
        onSubmit={(data, {completeForm, setIsSubmitting}) => {
          registerUserHandler(data, completeForm, setIsSubmitting);
        }}
      />
    </div>
  );
};

export default OnBoardingTypeform;
