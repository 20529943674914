import axios from 'axios'
import * as actionTypes from '../actionTypes'

const {REACT_APP_BACKEND_URL} = process.env;

export const generateMagicLink = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${REACT_APP_BACKEND_URL}magic/generate`, data)
      .then(async (result) => {
        await dispatch(generateMagicLinkSuccess(result.data.data))
      })
      .catch(async (err) => {
        const error = err.response && err.response.message
        await dispatch(generateMagicLinkFailed(error))
        throw new Error(error)
      })
  }
}

export const generateMagicLinkSuccess = (response) => {
  return {type: actionTypes.GENERATE_MAGIC_LINK_SUCCESS, response}
}

export const generateMagicLinkFailed = (error) => {
  return {type: actionTypes.GENERATE_MAGIC_LINK_FAILED, error}
}

export const consumeMagicLink = (data) => {
  return async (dispatch) => {
    return axios
      .post(`${REACT_APP_BACKEND_URL}magic/${data.token}`)
      .then(async (result) => {
        await dispatch(consumeMagicLinkSuccess(result.data.data))
        localStorage.setItem('auth', true)
      })
      .catch(async (err) => {
        const error = err.response && err.response.message
        await dispatch(consumeMagicLinkFailed(error))
        throw new Error(error)
      })
  }
}

export const consumeMagicLinkSuccess = (currentUser) => {
  return {type: actionTypes.CONSUME_MAGIC_LINK_SUCCESS, currentUser}
}

export const consumeMagicLinkFailed = (error) => {
  return {type: actionTypes.CONSUME_MAGIC_LINK_FAILED, error}
}

export const registerUser = (data, keepUser = true) => {
  return async (dispatch) => {
    return axios
      .post(`${REACT_APP_BACKEND_URL}register`, data)
      .then(async (result) => {
        if (keepUser) {
          await dispatch(registerUserSuccess(result.data.data))
          localStorage.setItem('auth', true)
        }
      })
      .catch(async (err) => {
        const error = err.response && err.response.message
        await dispatch(registerUserFailed(error))
        throw new Error(error)
      })
  }
}

export const registerUserSuccess = (currentUser) => {
  return {type: actionTypes.REGISTER_USER_SUCCESS, currentUser}
}

export const registerUserFailed = (error) => {
  return {type: actionTypes.REGISTER_USER_FAILED, error}
}

export const logoutUser = () => {
  localStorage.removeItem('auth')
  return {type: actionTypes.LOGOUT_USER_SUCCESS}
}
