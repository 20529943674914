import React, {useEffect, useState} from "react";
import logo from "../assets/checklijn.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Menu, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/actions/authActions";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await dispatch(logoutUser());
    setAnchorEl(null);
    history.push("/login");
  };

  const currentUser = useSelector(
    state => state.auth.currentUser
  );

  const subscriptionDetails = useSelector(
    state => state.subscription.subscriptionDetails
  );

  const billingUrl = useSelector(
    state => state.billing.redirectUrl
  );

  useEffect(() => {
    if (subscriptionDetails.is_expired_trial) {
      Swal.fire({
        icon: "warning",
        title: "Account gedeactiveerd",
        html: "Je account is momenteel gedeactiveerd. Om je account opnieuw te activeren en Checklijn voort te zetten, verzoeken wij je om de betaling te voldoen.<br><br>Klik op de onderstaande knop om de betaling te voldoen en je account weer te activeren.",
        confirmButtonText: "Betaling voltooien",
        confirmButtonColor: "#F8BB86",
      }).then((response) => {
        if (response.isConfirmed) {
          window.location.href = billingUrl;
        }
      });
    }
  }, [subscriptionDetails.is_expired_trial, billingUrl]);

  return (
    <>
      <div className="bg-neutral-100 shadow-md mx-8 my-4 px-4 py-2 rounded-md">
        <div className="w-full">
          <div className="flex flex-row justify-between">
            <img src={logo} className="w-32" alt="checklijn logo" />
            <div>
              <div className="flex flex-row">
              <span className="cursor-pointer" onClick={handleClick}>
                <AccountCircleIcon fontSize="large" />
              </span>
              </div>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem><a href={billingUrl}>Facturatie</a></MenuItem>
                <MenuItem onClick={handleLogout}>Uitloggen</MenuItem>
              </Menu>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-8">
        <div
          className="bg-blue-100 text-shadow text-secondary-300 px-4 py-3 mb-4 shadow-lg rounded-lg font-serif"
          role="alert"
        >
          <div className="text-sm">
            <span className="font-semibold">
              Welkom {currentUser.name ? currentUser.name : ""}!
            </span>{" "}
            <span>
              {subscriptionDetails.readable_status}{" "}
              <a href={billingUrl} className="cursor-pointer underline text-sm">
                {
                  subscriptionDetails.is_manageable
                    ? `Klik hier om uw abonnement te beheren. `
                    : `Klik hier om uw abonnement te activeren. `
                }
              </a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
