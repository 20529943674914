export const SET_LOADING = 'SET_LOADING';
export const GENERATE_MAGIC_LINK_SUCCESS = 'GENERATE_MAGIC_LINK_SUCCESS';
export const GENERATE_MAGIC_LINK_FAILED = 'GENERATE_MAGIC_LINK_FAILED';
export const CONSUME_MAGIC_LINK_SUCCESS = 'CONSUME_MAGIC_LINK_SUCCESS';
export const CONSUME_MAGIC_LINK_FAILED = 'CONSUME_MAGIC_LINK_FAILED';
export const FETCH_BILLING_LINK_SUCCESS = 'FETCH_BILLING_LINK_SUCCESS';
export const FETCH_BILLING_LINK_FAILED = 'FETCH_BILLING_LINK_FAILED';
export const FETCH_RECIPIENT_SUCCESS = 'FETCH_RECIPIENT_SUCCESS';
export const FETCH_RECIPIENT_FAILED = 'FETCH_RECIPIENT_FAILED';
export const FETCH_USER_EMERGENCY_DETAILS_SUCCESS = 'FETCH_USER_EMERGENCY_DETAILS_SUCCESS';
export const FETCH_USER_EMERGENCY_DETAILS_FAILED = 'FETCH_USER_EMERGENCY_DETAILS_FAILED';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const SET_RECEPIENT_DETAILS = 'SET_RECEPIENT_DETAILS';
export const FETCH_SUBSCRIPTION_SUCCESS = 'FETCH_SUBSCRIPTION_SUCCESS';
export const FETCH_SUBSCRIPTION_FAILED = 'FETCH_SUBSCRIPTION_FAILED';
