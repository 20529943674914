import * as types from "../actionTypes";
import produce from "immer";

const initialState = {
  redirectUrl: '',
  errors: [],
};

export default function billingReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case types.FETCH_BILLING_LINK_SUCCESS:
      return produce(state, (draft) => {
        draft.redirectUrl = action.redirectUrl;
        draft.errors = [];
      });
    case types.FETCH_BILLING_LINK_FAILED:
      return produce(state, (draft) => {
        draft.redirectUrl = '';
        draft.errors = [action.error];
      });
    default:
      return state;
  }
}
