import { MenuItem, Select } from "@mui/material";
import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import { timeSlots } from "../utils";
import EmergencyDetailsTable from "./EmergencyDetailsTable";
import EmergencyDetailsModal from "./EmergencyDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReceipientDetails,
  fetchUserEmergencyDetails,
  setRecepientDetails,
  updatedRecipient,
} from "../redux/actions/recipientActions";
import { NotificationManager } from "react-notifications";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";

const Dashboard = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const recipientDetails = useSelector(
    (state) => state.recipient.recipientDetails
  );
  const filteredEmergencyDetails = useSelector(
    (state) => state.recipient.emergencyDetails.filter(contact => contact.status === 'approved')
  );
  const updatedRecipientDetails = useSelector(
    (state) => state.recipient.updatedRecipientDetails
  );

  const handleRecipientChange = async (key, value) => {
    await dispatch(setRecepientDetails(key, value));
  };
  const updateHandler = async () => {
    try{
      await dispatch(updatedRecipient());
      NotificationManager.success("Recipient Details updated!")
    }
    catch(error){
      NotificationManager.error("Update recipient details failed!")
    }
  };

  useEffect(() => {
    dispatch(fetchReceipientDetails());
    dispatch(fetchUserEmergencyDetails());

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('message')) {
      NotificationManager.info(searchParams.get('message'));
      searchParams.delete('message');
      history.replace({
        search: searchParams.toString(),
      });
    }
    if (searchParams.has('register')) {
      const registerInput = searchParams.get('register');
      Swal.fire({
        icon: "success",
        title: "Account succesvol aangemaakt!",
        html: `Je account is nu gecreëerd en de dagelijkse belletjes zijn geactiveerd. Om ervoor te zorgen dat er ook gealarmeerd wordt, vragen we je om een sleutelfiguur toe te voegen.<br><br>De sleutelfiguur zal gealarmeerd worden als ${registerInput} niet in staat ${registerInput === 'jij' ? 'bent' : 'is'} om in te checken voor de dagelijkse check, wat kan wijzen op een noodsituatie.`,
        confirmButtonText: "Sleutelfiguur toevoegen",
      }).then((response) => {
        if (response.isConfirmed) {
          setModalOpen(true);
        }
      });
      searchParams.delete('register');
      history.replace({
        search: searchParams.toString(),
      });
    }
  }, [dispatch, history, location.search]);

  let timeSlot1 = "";
  let timeSlot2 = "";
  let timeSlotsPrompt = <>N/A</>;
  if (recipientDetails && recipientDetails.calling_timeslot1) {
    timeSlot1 = recipientDetails.calling_timeslot1.slice(0, -3);
    timeSlotsPrompt = <span className="font-bold">{timeSlot1}</span>;
  }
  if (recipientDetails && recipientDetails.calling_timeslot2) {
    timeSlot2 = recipientDetails.calling_timeslot2.slice(0, -3);
    timeSlotsPrompt = timeSlot1
        ? <><span className="font-bold">{timeSlot1}</span> en <span className="font-bold">{timeSlot2}</span></>
        : <span className="font-bold">{timeSlot2}</span>;
  }

  let emergencyDetailsPrompt = <>
    Als {recipientDetails.name} na de laatste poging niet in staat is om op te nemen, zullen wij niemand hiervan op de hoogte stellen. <span className="underline cursor-pointer" onClick={() => setModalOpen(true)}>Klik hier om een sleutelfiguur toe te voegen.</span>
  </>;
  if (filteredEmergencyDetails && filteredEmergencyDetails.length > 0) {
    emergencyDetailsPrompt = `Als ${recipientDetails.name} na de laatste poging niet in staat is om op te nemen, zullen wij `;
    let totalEmergencyDetails = filteredEmergencyDetails.length;
    filteredEmergencyDetails.forEach(contact => {
      emergencyDetailsPrompt += contact.name;
      emergencyDetailsPrompt += --totalEmergencyDetails > 1 ? ', ' : totalEmergencyDetails > 0 ? ' en ' : '';
    });
    emergencyDetailsPrompt += ` hiervan op de hoogte stellen.`;
    emergencyDetailsPrompt = <>{emergencyDetailsPrompt}</>;
  }

  return (
    <div className="bg-secondary-100 overflow-auto">
      <Header />
      <div className="mx-8">
        <div
          className={ filteredEmergencyDetails.length > 0
            ? 'bg-success-100 text-success-500 font-medium shadow-md rounded-lg text-sm'
            : 'bg-error-100 text-error-300 font-medium shadow-md rounded-lg text-sm'
          }
          role="alert"
        >
          <div className="font-bold text-sm shadow-md px-4 py-2">Status</div>
          <p className="px-4 py-3">
            {recipientDetails && recipientDetails.name && (
              <>
                {recipientDetails.name} wordt dagelijks om {timeSlotsPrompt}{" "}
                uur gebeld en wordt gevraagd om op de 1 toets te drukken. Als{" "}
                {recipientDetails.name} niet in staat is om op te nemen, bellen{" "}
                we in dat uur nog vier keer. {emergencyDetailsPrompt}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="m-8 shadow bg-neutral-100 rounded-md">
        <form className="bg-white rounded px-8 pt-6 pb-4 mb-4">
          <div className="pb-8 text-lg font-medium text-neutral-dark-100">
            Ontvanger
          </div>
          <div className="grid grid-cols-2 gap-4 md:gap-8 lg:gap-16">
          <div className="col-span-2 md:col-span-1">
            <div className="mb-4">
              <label className="block text-sm mb-1 text-font text-neutral-600">
                Naam
              </label>
              <input
                className="shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100"
                type="text"
                placeholder="Recipient Name"
                value={
                  updatedRecipientDetails && updatedRecipientDetails.name
                    ? updatedRecipientDetails.name
                    : ""
                }
                onChange={(e) => handleRecipientChange("name", e.target.value)}
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-1 text-font text-neutral-600">
                Telefoonnummer
              </label>
              <input
                className="shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100"
                placeholder="e.g +31 900890022"
                value={
                  updatedRecipientDetails &&
                  updatedRecipientDetails.phone_number
                    ? updatedRecipientDetails.phone_number
                    : ""
                }
                onChange={(e) =>
                  handleRecipientChange("phone_number", e.target.value)
                }
              />
            </div>
          </div>
          <div className="col-span-2 md:col-span-1">
            <div className="mb-4">
              <label
                className="block text-sm mb-1 text-font text-neutral-600"
                htmlFor="name"
              >
                Tijdstip 1
              </label>
              <Select
                value={
                  updatedRecipientDetails &&
                  updatedRecipientDetails.calling_timeslot1
                    ? updatedRecipientDetails.calling_timeslot1
                    : "None"
                }
                onChange={(e) =>
                  handleRecipientChange("calling_timeslot1", e.target.value)
                }
                className="h-10 shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100"
              >
                {timeSlots.map((slot) => (
                  <MenuItem key={slot.label} value={slot.value}>{slot.label}</MenuItem>
                ))}
              </Select>
            </div>
            <div className="mb-4">
              <label className="block text-sm mb-1 text-font text-neutral-600">
                Tijdstip 2
              </label>
              <Select
                value={
                  updatedRecipientDetails &&
                  updatedRecipientDetails.calling_timeslot2
                    ? updatedRecipientDetails.calling_timeslot2
                    : "None"
                }
                onChange={(e) =>
                  handleRecipientChange("calling_timeslot2", e.target.value)
                }
                className="h-10 shadow appearance-none border border-neutral-300 rounded w-full py-2 px-3 text-neutral-400 leading-tight focus:outline-none focus:shadow-outline focus:border-primary-100"
              >
                {timeSlots.map((slot) => (
                  <MenuItem key={slot.label} value={slot.value}>{slot.label}</MenuItem>
                ))}
              </Select>
            </div>
          </div>
          </div>
          <div className="flex flex-row justify-end">
            <button
              className="bg-primary-100 text-neutral-100 py-2 px-4 rounded focus:outline-none focus:shadow-outline my-4 w-full md:max-w-[8rem]"
              type="button"
              onClick={updateHandler}
            >
              Opslaan
            </button>
          </div>
        </form>
      </div>
      <EmergencyDetailsTable setModalOpen={setModalOpen} />
      <EmergencyDetailsModal isModalOpen={isModalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default Dashboard;
