import * as types from "../actionTypes";
import produce from "immer";

const intialState = {
  recipientDetails: {},
  emergencyDetails: [],
  updatedRecipientDetails : {}
};

export default function recipientReducer(state = { ...intialState }, action) {
  switch (action.type) {
    case types.FETCH_RECIPIENT_SUCCESS:
      return produce(state, (draft) => {
        draft.recipientDetails = action.res;
        draft.updatedRecipientDetails = action.res
      });
    case types.FETCH_RECIPIENT_FAILED:
      return produce(state, (draft) => {
        draft.recipientDetails = {};
        draft.updatedRecipientDetails = {}
      });

    case types.FETCH_USER_EMERGENCY_DETAILS_SUCCESS:
      return produce(state, (draft) => {
        draft.emergencyDetails = action.res;
      });
    case types.FETCH_USER_EMERGENCY_DETAILS_FAILED:
      return produce(state, (draft) => {
        draft.emergencyDetails = [];
      });
    case types.SET_RECEPIENT_DETAILS:
      return produce(state, (draft) => {
        draft.updatedRecipientDetails = {
          ...state.updatedRecipientDetails,
          [action.key] : action.value
        };
      });
    default:
      return state;
  }
}
